mat-card.mat-card.center-align mv-form-body {
  /* padding-top: 3rem !important; */
  /* padding-bottom: 2rem !important; */
  text-align: center;
}

/* Vertical/Y Margins */
.mv-form-body {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mat-card.single-column.padding-sm {
  padding-top: 3rem;
  padding-bottom: 2rem;
}
